<template>
  <div id="signin">
    <v-card width="400" class="mx-auto mt-5">
      <v-card-title>
        <h1>Login</h1>
      </v-card-title>
      <v-card-text>
        <v-form @submit.prevent="onSubmit">
          <v-text-field
            label="Username"
            v-model="userName"
            prepend-icon="mdi-account-circle"
            />
          <v-text-field
            :type="showPassword ? 'text' : 'password'"
            label="Password"
            v-model="password"
            prepend-icon="mdi-lock"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
          />
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="success">Register</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="info" @click="onSubmit">Login</v-btn>
      </v-card-actions>
    </v-card>

 
    
  </div>
</template>

<script>
import store from '@/store'

  export default {
    data () {
      return {
        userName: '',
        password: '',
        showPassword: false,
      }
    },
    methods: {
      onSubmit() {        
        //working//this.$router.push('/')
        
        const formData = {
          userName: this.userName,
          password: this.password,
        }
        this.$store.dispatch('login', formData)
        // .then( () => { 
        //   this.$router.push('/addstock')
        //   }, function() {})
        
      }
    },
    computed: {
      userAuthenticated () {
        return !this.$store.getters.user ? false : this.$store.getters.user.userName
      }
    },
    watch: {
    '$store.state.idToken': function() {
      if (store.state.idToken) {
        this.$router.push('/')
      }
    }
  }
  }
</script>

<style scoped>
  .signin-form {
    width: 400px;
    margin: 30px auto;
    border: 1px solid #eee;
    padding: 20px;
    box-shadow: 0 2px 3px #ccc;
  }

  .input {
    margin: 10px auto;
  }

  .input label {
    display: block;
    color: #4e4e4e;
    margin-bottom: 6px;
  }

  .input input {
    font: inherit;
    width: 100%;
    padding: 6px 12px;
    box-sizing: border-box;
    border: 1px solid #ccc;
  }

  .input input:focus {
    outline: none;
    border: 1px solid #521751;
    background-color: #eee;
  }

  .submit button {
    border: 1px solid #521751;
    color: #521751;
    padding: 10px 20px;
    font: inherit;
    cursor: pointer;
  }

  .submit button:hover,
  .submit button:active {
    background-color: #521751;
    color: white;
  }

  .submit button[disabled],
  .submit button[disabled]:hover,
  .submit button[disabled]:active {
    border: 1px solid #ccc;
    background-color: transparent;
    color: #ccc;
    cursor: not-allowed;
  }
</style>